import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import StatusIcon from '../StatusIcons2';

import TimelineTwoToneIcon from '@mui/icons-material/TimelineTwoTone';
import ForumTwoToneIcon from '@mui/icons-material/ForumTwoTone';
import ThumbsUpDownTwoToneIcon from '@mui/icons-material/ThumbsUpDownTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import ShieldTwoToneIcon from '@mui/icons-material/ShieldTwoTone';
import SecurityTwoToneIcon from '@mui/icons-material/SecurityTwoTone';
import BusinessCenterTwoToneIcon from '@mui/icons-material/BusinessCenterTwoTone';

function PolicyBlock(props){
    const session = props?.session;
    const reporting = session?.reporting;
    const branch = props?.branch;
    const stem = props?.stem;
    const currentCase = props?.currentCase;
    const accountData = session?.user?.data?.accountData;
    const multipleAccounts = Object.keys(accountData).length - ("opportunityTypes" in accountData ? 1 : 0) > 1;
    const attributeData = session?.[reporting?.data?.dataPointer]?.data?.attributeData;

    const [timeAgo, setTimeAgo] = useState('');
    const marketing = currentCase?.type === "marketing";
    // const matchingAccount = marketing ? Object.values(accountData).find(account => account?.accountID === currentCase?.relatedNationalAccountID) : accountData?.[currentCase?.relatedAccountID]
    const matchingAccount = accountData?.[currentCase?.relatedAccountID];
    const Element = marketing ? "div" : Link; // Use Link if non-clickable, otherwise div
    const secondary = currentCase?.secondaryFirstName || currentCase?.secondaryLastName;

    const activites = () => {
        if (marketing) return;
    
        const activityHandler = {
            timeline: {
                count: currentCase?.unreadTimeline,
                icon: <TimelineTwoToneIcon />
            },
            messages: {
                count: currentCase?.numMessagesRead,
                icon: <ForumTwoToneIcon />
            },
            bids: {
                count: currentCase?.numUnreadBids,
                icon: <ThumbsUpDownTwoToneIcon />
            }
        };
    
        return Object.values(activityHandler).map((activity, index) => (
            <div
                key={index}
                className={`activityStatus cC gC${index + 2}${activity?.count ? " active" : ''}`}
            >
                {activity.icon}
            </div>
        ));
    };

    const cellValue = (column) => {
        return (
            props?.printingFunctions?.renderFormattedColumnValue(
                column?.value,
                attributeData?.[column?.columnName]?.returnType,
                props?.searchData,
            )
        );
    };

    useEffect(() => {
        const updateTime = () => {
            setTimeAgo(session?.env?.functions?.timeAgo(currentCase?.lastUpdate));
        };

        // Initial update
        updateTime();

        // Update every second
        const intervalId = setInterval(updateTime, 1000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [currentCase?.lastUpdate]);

    return (
        <div className="item g">
            {multipleAccounts && (
                <div className="relatedAccount f g cR">
                    {marketing ? currentCase?.sourceName : matchingAccount?.name}
                </div>
            )}
            {React.createElement(
                Element,
                {
                    key: currentCase?.recordID + currentCase?.type,
                    className: `block bR g dG dP`,
                    // onClick: () => console.log(currentCase),
                    ...(Element === Link && { to: `/policies/${currentCase?.type}/${currentCase?.recordID}`}),
                },
                <>
                    <div className={`header g dG cC`}>
                        {currentCase?.readStatus === false && !marketing &&
                            <div className="f cC">
                                <div className="notificationAlert">
                                </div>
                            </div>
                        }
                        {activites()}
                        <div
                            className="gC6 cC"
                            title={`${
                                session?.env?.functions?.reformatDate(currentCase?.lastUpdate, "timestamp")} on ${
                                    session?.env?.functions?.reformatDate(currentCase?.lastUpdate)}`
                            }
                        >
                            {timeAgo}
                        </div>
                        <div className="gC7 cC">
                            {secondary ? <PeopleAltTwoToneIcon/> : <PersonOutlineTwoToneIcon/>}
                        </div>
                        <div className="gC8 cC">
                            {{
                                "marketing": <BusinessCenterTwoToneIcon style={{ color: "#795548" }} />,
                                "origination": <ShieldTwoToneIcon style={{ color: "rgb(0, 191, 255)" }} />,
                                "servicing": <SecurityTwoToneIcon style={{ color: "rgb(255, 152, 0)" }} />,
                            }[currentCase?.type]}
                        </div>
                    </div>
                    <div className="divider" />
                    <div className="content g cC">
                        <div
                            className="cL g dG f"
                            title={currentCase?.policyStatus}
                        >
                            <StatusIcon
                                status={currentCase?.policyStatus}
                                type={"Status"}
                            />
                            <div className="gC2 f cC gCW">
                                {cellValue({ value: currentCase?.policyStatus, columnName: "policyStatus" })}
                            </div>
                        </div>
                        <div
                            className="f gCW"
                            title={currentCase?.policyNumber}
                        >
                            {cellValue({ value: currentCase?.policyNumber, columnName: "policyNumber" })}
                        </div>
                        <div
                            className="f gCW"
                            title={`${currentCase?.primaryFirstName}`}
                        >
                            {cellValue({ value: currentCase?.primaryFirstName, columnName: "primaryFirstName" })}
                        </div>
                        <div
                            className="f gCW"
                            title={`${currentCase?.primaryLastName}`}
                        >
                            {cellValue({ value: currentCase?.primaryLastName, columnName: "primaryLastName" })}
                        </div>
                        <div
                            className="f gCW"
                            title={session?.env?.functions?.convertIntToCurrency(currentCase?.deathBenefit)}
                        >
                            {cellValue({ value: currentCase?.deathBenefit, columnName: "deathBenefit" }) ?? "$0"}
                        </div>
                        <div
                            className="cR f gCW f"
                            title={currentCase?.insuranceCompany}
                        >
                            {cellValue({ value: currentCase?.insuranceCompany, columnName: "insuranceCompany" })}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default PolicyBlock;