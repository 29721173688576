import React from 'react';
import { useState, useEffect, useRef } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import SpaceDashboardTwoToneIcon from '@mui/icons-material/SpaceDashboardTwoTone';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';

import InputField from "../Components/InputField.js";
import ViewBarBtn from '../Components/ViewBarBtn.js';
import ReportSheet from '../Components/Reporting/ReportSheet.js';
import ReportList from '../Components/Reporting/ReportList.js';

function Dashboard(props){
    const session = props?.session;
    const reporting = session?.reporting;
    const dashboard = props?.session?.dashboard;
    const reportTable = useRef(null);
    const [pagination, setPagination] = useState({
        showMenu : false,
        rowMax : props?.session?.storage?.reporting?.rowMax ?? 100,
        index : props?.selectedReport?.lastPageIndex ?? 1,
    });

    const [menuStatus, setMenuStatus] = useState({
        hover : false,
        open : false,
    });
    const visibleMenu = menuStatus?.hover || menuStatus?.open;

    const updateMenuStatus = (attr, value) => {
        setMenuStatus((prevStatus) => ({
            ...prevStatus,
            [attr]: value,
        }));
    };

    const resetShowMenu = (setDropdownState) => {
        setDropdownState((prev) => {
            if (prev?.showMenu) {
                return { ...prev, showMenu: false };
            }
            return prev;
        });
    };

    const resetDropdowns = () => {
        // if(pagination?.showMenu){
        //     resetShowMenu(setPagination);
        // }
    };

    useEffect(() => {
    }, []);

    const currentComponent = 2;
    const currentReport = dashboard?.data?.selectedDashboard?.components?.[currentComponent]?.selectedReport || {};
    const items = props?.session?.[reporting?.data?.dataPointer]?.data?.
        [currentReport?.referenceBranch ?? currentReport?.branch]?.
            [currentReport?.referenceStem ?? currentReport?.stem];
    currentReport.list = reporting?.functions?.filterItemsWithQuery(currentReport?.query, items);
    const reportType = reporting?.functions?.reportType(currentReport?.stem);

    function printMenu(){
        const menuOptions = [
            {
                "prompt" : "Menu",
                "icon" : <MenuTwoToneIcon/>
            },
            {
                "prompt" : "Create",
                "icon" : <AddCircleTwoToneIcon/>
            },
            {
                "prompt" : "Dashboards",
                "icon" : <SpaceDashboardTwoToneIcon/>
            },
        ]

        return (
            menuOptions?.map((option, index) => (
                <div key={index} className="option g">
                    <div className="f cC">{option?.icon}</div>
                    <div className="f cC gCW">
                        {visibleMenu ? option?.prompt : ''}
                    </div>
                </div>
            ))
        )
    }

    return (
        <div className="dashboard">
            <div className="dashboardHub f g dG bR oH">
                <div
                    className={`menuBar g f${visibleMenu ? " expanded" : ''}`}
                    onMouseOver={() => updateMenuStatus("hover", true)}
                    onMouseOut={() => updateMenuStatus("hover", false)}
                >
                    {printMenu()}
                </div>
                <div className="canvas">
                    {
                    session?.[reporting?.data?.dataPointer]?.data ?
                    <div
                        className="reportBuilder remote g oH"
                        key="reportBuilder"
                    >
                        <div
                            className="remoteReportTopBar g cC bold fR s e"
                            onClick={()=>{
                                console.log(props?.session);
                                //props?.session?.set("dashboard", "test.example", "abc"); /*;klajsd f;aklsdjf8*/
                            }}
                        >
                            {reportType === "dynamic" && currentReport?.subReport?.list &&
                                <div className="f cL">
                                    <div
                                        className="btnWIcon gC2 bR dG cC p bold lH"
                                        onClick={()=>{
                                            const subReport = {...currentReport?.subReport};
                                            subReport.list = undefined;
                                            subReport.columns = undefined;
                                            subReport.name = undefined;
                                            console.log(subReport);
                                            console.log(`selectedDashboard.components.${currentComponent}.selectedReport.subReport`);
                                            session?.set("dashboard", `selectedDashboard.components.${currentComponent}.selectedReport.subReport`, subReport);
                                        }}
                                    >
                                        <ChevronLeftIcon/>
                                        <div className="f cC e">
                                            {currentReport?.details?.name ?? "Unnamed Report"}
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="gC2">
                                {currentReport?.details?.name}
                            </div>
                        </div>
                        <ReportSheet
                            session={session}
                            remoteReport={currentReport ?? undefined}
                            selectedDashboard={dashboard?.data?.selectedDashboard?.components?.[currentComponent]}
                            remotePath={`selectedDashboard.components[${currentComponent}].`}
                            remotePathEnd={`selectedDashboard.components[${currentComponent}]`}
                            reportType={"inherited"}
                            pagination={{data : pagination, set : setPagination}}
                            baseModule={"dashboard"}
                            baseData={dashboard?.data?.selectedDashboard?.components?.[currentComponent]}
                        />
                    </div>
                    : "Loading"}
                </div>
            </div>
        </div>
    )
}

export default Dashboard;