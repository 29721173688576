import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import './HomePage.css';
import '../Components/DonutChart/DonutChart.css';

import StatusIcon from "../Components/StatusIcons";
import { convertIntToCurrency } from '../envFunctions.js';

import CircularProgress from '@mui/material/CircularProgress';
import StartIcon from '@mui/icons-material/Start';
import DiamondIcon from '@mui/icons-material/Diamond';
import GavelIcon from '@mui/icons-material/Gavel';
import TaskIcon from '@mui/icons-material/Task';
import FlakyIcon from '@mui/icons-material/Flaky';

function PolicyTrackline(props){
    const session = props?.session;
    const reporting = session?.reporting;
    const policies = session?.policies;
    const selectedReport = session?.policies?.data?.selectedReport;
    const attributeData = session?.[reporting?.data?.dataPointer]?.data?.attributeData;

    const criterionTemplate = (attr, operator, value) => {
        const attrtibute = attributeData?.[attr];
    
        return {
            id: `${attr}-${value}`,
            formType: attrtibute?.formType,
            criterion: {
                [operator]: value,
            },
            relativeKey: undefined,
            groupID: 0,
            groupOperator: "AND",
            inlineOperator: "AND",
            attr: attr,
        }
    };

    function focusStatus(focus, group) {
        const statuses = [
            "Application Sent",
            "Meds/Illustration Ordered",
            "Underwriting (LE's)",
            "Pre-Priced",
            "Pricing / Valuation",
            "Shipped",
            "Bids",
            "Offer Letter Out / CRF",
            "Offer Letter In",
            "Contracts Out",
            "Contracts Back",
            "Contracts Back (Digital)",
            "Escrow / At Carrier",
            "Rescission",
            "Closed Won",
            "Closed Lost",
            "On Hold",
            "Client Not Interested",
            "Closed Lost no bid",
            "Closed Lost with Bid",
            "Unqualified Closed",
            "Invoicing",
            "Client Deceased", //NEW
            "Closed Lost to Competitor", //NEW
            "Failed to Contact",
            "Sent to 3rd Party",
            "Contact Attempted",
            "Matured During Rescission",
            "On Hold/Pre-Priced",
            "Lead",
        ];

        const excludingStatuses = statuses.filter(status => !focus.includes(status));

        const config = {
            session,
            baseModule : "policies",
            existingCriteria : selectedReport?.criteria?.existing,
            // setExistingCriteria,
            // blankCriteriaConfig,
            criterionTemplate,
            refresh : true,
        }

        reporting?.functions?.toggleCriteria(config, reporting?.functions?.groupCriteria(excludingStatuses, "policyStatus", "not_match"), true);

        let focusedStatus = [focus];
        // const newStatusGroup = { ...props.filterHandler?.status};

        // Object.keys(newStatusGroup).forEach((key) => {
        //     if(typeof newStatusGroup[key] === "object"){
        //         if(focus.includes(key) && newStatusGroup[key].accountType === "origination"){
        //             newStatusGroup[key].active = true;
        //         }else{
        //             newStatusGroup[key].active = false;
        //         }
        //     }
        // });

        // props.setFilterHandler({
        //   ...props.filterHandler,
        //   status: newStatusGroup,
        // });
    }

    function printTrackedCategory(status, group){
        let activeStatus = "inactive";
        let count = 0;
        let deathBenefit = 0;

        if(group){
            if(status === "Contracts Back"){
                if(props.chartData?.["Contracts Back"]){
                    count += props.chartData?.["Contracts Back"].count;
                    deathBenefit += props.chartData?.["Contracts Back"].deathBenefit;
                }
                if(props?.chartData?.["Contracts Back (Digital)"]){
                    count += props.chartData?.["Contracts Back (Digital)"].count;
                    deathBenefit += props.chartData?.["Contracts Back (Digital)"].deathBenefit;
                }
            }
        }else{
            if(props.chartData?.[status]){
                count += props.chartData?.[status].count;
                deathBenefit += props.chartData?.[status].deathBenefit;
            }
        }

        if(count > 0){
            activeStatus = "active";
        }

        if(activeStatus === "active"){
            return (
                <Link
                    to="/policies"
                    onClick={()=>{focusStatus(group ? ["Contracts Back", "Contracts Back (Digital)"] : status)}}
                    className={"categoryBlock g cC bR bold " + activeStatus}
                >
                    <StatusIcon currentStatus={status}/>
                    <div className="cL f gCW">
                        {status}
                    </div>
                    <div className="f cR">
                        {convertIntToCurrency(deathBenefit)}
                    </div>
                    <div className="cC">
                        {count}
                    </div>
                </Link>
            )
        }

        return (
            <div className={"categoryBlock g cC bR bold " + activeStatus}>
                <StatusIcon currentStatus={status}/>
                <div className="cL f gCW">
                    {status}
                </div>
                <div className="f cR">
                    {convertIntToCurrency(deathBenefit)}
                </div>
                <div className="cC">
                    {count}
                </div>
            </div>
        )
    }

    function printClosingTrack(position, status, group){
        let count = 0;
        let activeStatus = "inactive";
        let deathBenefit = 0;

        const groupArray = {
            "closedLost" : [
                "Client Not Interested",
                "Closed Lost",
                "Closed Lost no bid",
                "Closed Lost with Bid",
                "Unqualified Closed"
            ],
            "closedWon" : [
                "Closed Won",
                "Invoicing"
            ]
        }

        if(group){
            for (const item of groupArray[group]) {
                const deathBenefitValue = props?.chartData?.[item]?.deathBenefit;
                const countValue = props?.chartData?.[item]?.count;
    
                if (!isNaN(deathBenefitValue)) {
                    deathBenefit += deathBenefitValue;
                }
    
                if (!isNaN(countValue)) {
                    count += countValue;
                }
            }
        }else{
            if(props.chartData?.[status]){
                count += props.chartData?.[status].count;
                deathBenefit += props.chartData?.[status].deathBenefit;
            }
        }

        if(count > 0){
            activeStatus = "active";
        }

        if(activeStatus === "active"){
            return (
                <Link
                    to="/policies"
                    onClick={()=>{focusStatus(group ? groupArray[group] : status)}}
                    className={position + " closedCat cC bR dP fC g bold p " + activeStatus}>
                    <div key="status">
                        {`${count} ${status === "Closed Lost" ? "Closed Out" : status}`}
                    </div>
                    <StatusIcon currentStatus={status}/>
                    <div key="data" className="data cC g f">
                        <div className="f cC">
                            {convertIntToCurrency(deathBenefit, true)}
                        </div>
                    </div>
                </Link>
            );
        }

        return (
            <div className={position + " closedCat cC bR dP fC g bold " + activeStatus}>
                <div key="status">
                    {`${count} ${status === "Closed Lost" ? "Closed Out" : status}`}
                </div>
                <StatusIcon currentStatus={status}/>
                <div key="data" className="data cC g f">
                    <div className="f cC">
                        {convertIntToCurrency(deathBenefit, true)}
                    </div>
                </div>
            </div>
        );
    }

    function printTrackHeader(position){
        let deathBenefit = 0;
        let count = 0;

        const positionData = {
            first: {
                category : "Documents / Gathering",
                icon : <StartIcon/>
            },
            second : {
                category : "Valuation",
                icon : <DiamondIcon/>
            },
            third : {
                category : "Contracts",
                icon : <GavelIcon/>
            },
            fourth : {
                category : "Closing",
                icon : <TaskIcon/>
            },
            fifth : {
                category : "Closed / Inactive",
                icon : <FlakyIcon/>
            }
        };

        const positionObj = {
            first: [
                // "Lead",
                "Application Sent",
                "Meds/Illustration Ordered",
                "Underwriting (LE's)"
            ],
            second: [
                "Pre-Priced",
                "Pricing / Valuation",
                "Shipped",
                "Bids"
            ],
            third: [
                "Offer Letter Out / CRF",
                "Offer Letter In",
                "Contracts Out",
                "Contracts Back",
                "Contracts Back (Digital)"
            ],
            fourth: [
                "Escrow / At Carrier",
                "Rescission",
                // "Invoicing"
            ],
            fifth: [
                "Closed Won",
                "Closed Lost",
                "On Hold"
            ]
        };

        const arrayToIterate = positionObj[position];

        for (const item of arrayToIterate) {
            const deathBenefitValue = props?.chartData?.[item]?.deathBenefit;
            const countValue = props?.chartData?.[item]?.count;

            if (!isNaN(deathBenefitValue)) {
                deathBenefit += deathBenefitValue;
            }

            if (!isNaN(countValue)) {
                count += countValue;
            }
        }

        // props?.chartData?.["Contracts Back (Digital)"]

        return (
            <div
                className={"header cC f e " + position}>
                {position !== "fifth" &&
                    <div className="bullet f">
                    </div>
                }
                <div className="cC f g bold oH gC2">
                    <div className="cC">
                        {positionData?.[position]?.icon}
                    </div>
                    <Link
                        to="/policies"
                        onClick={()=>{focusStatus(arrayToIterate)}}
                        className="gC3 cC g f gCW"
                    >
                        {positionData?.[position]?.category}
                    </Link>
                    {position !== "fifth" &&
                        <div className="cC g f gCW">
                            {convertIntToCurrency(deathBenefit, true)}
                        </div>
                    }
                    {position !== "fifth" &&
                        <div className="cC g f gCW">
                            {count}
                        </div>
                    }
                </div>
            </div>
        )
    }

    function printClosingCategory(){
        return (
            <div className="category f fifth">
                {printClosingTrack("won gC2", "Closed Won", "closedWon")}
                {printClosingTrack("lost", "Closed Lost", "closedLost")}
                {printClosingTrack("onHold", "On Hold")}
            </div>
        )
    }

    return (
        <div className="track f fC g cC">
            <div className="progressTrack g f cC fC">
                {printTrackHeader("first")}
                <div className="category f first">
                    <div className="vDivider f">
                    </div>
                    <div className="catWrap cC bR dP fC">
                        {printTrackedCategory("Lead")}
                        {printTrackedCategory("Application Sent")}
                        {printTrackedCategory("Meds/Illustration Ordered")}
                        {printTrackedCategory("Underwriting (LE's)")}
                    </div>
                </div>
                {printTrackHeader("second")}
                <div className="category f second">
                    <div className="vDivider f">
                    </div>
                    <div className="catWrap cC bR dP fC">
                        {printTrackedCategory("Pre-Priced")}
                        {printTrackedCategory("Pricing / Valuation")}
                        {printTrackedCategory("Shipped")}
                        {printTrackedCategory("Bids")}
                    </div>
                </div>
                {printTrackHeader("third")}
                <div className="category f third">
                    <div className="vDivider f">
                    </div>
                    <div className="catWrap cC bR dP fC">
                        {printTrackedCategory("Offer Letter Out / CRF")}
                        {printTrackedCategory("Offer Letter In")}
                        {printTrackedCategory("Contracts Out")}
                        {printTrackedCategory("Contracts Back", true)}
                    </div>
                </div>
                {printTrackHeader("fourth")}
                <div className="category f fourth ">
                    <div className="vDivider f">
                    </div>
                    <div className="catWrap cC bR dP fC">
                        {printTrackedCategory("Escrow / At Carrier")}
                        {printTrackedCategory("Rescission")}
                        {/* {printTrackedCategory("Invoicing")} */}
                    </div>
                </div>
                <div className="divider fifth">
                </div>
                {printTrackHeader("fifth")}
                {printClosingCategory()}
            </div>
        </div>
    )
}

export default PolicyTrackline;
