import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import StatusIcon from '../StatusIcons2';

import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import ShieldTwoToneIcon from '@mui/icons-material/ShieldTwoTone';
import SecurityTwoToneIcon from '@mui/icons-material/SecurityTwoTone';
import BusinessCenterTwoToneIcon from '@mui/icons-material/BusinessCenterTwoTone';
import StartIcon from '@mui/icons-material/Start';
import DiamondTwoToneIcon from '@mui/icons-material/DiamondTwoTone';
import GavelIcon from '@mui/icons-material/Gavel';
import TaskTwoToneIcon from '@mui/icons-material/TaskTwoTone';
import FlakyIcon from '@mui/icons-material/Flaky';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';

function Filters(props){
    const session = props?.session;
    const {baseModule, baseData, selectedReport, remotePath} = props?.overlayProps;
    const reporting = session?.reporting;
    const policies = props?.session?.policies;
    const attributeData = session?.[reporting?.data?.dataPointer]?.data?.attributeData;
    const [existingCriteria, setExistingCriteria] = useState([...selectedReport?.criteria?.existing] ?? blankCriteriaConfig);
    const [isHovering, setIsHovering] = useState(undefined);

    // const existingCriteria = {...selectedReport?.criteria?.existing};
    const blankCriteriaConfig = [{
        "attr": "recordID",
        "criterion": { "not_blank": true },
        "formType": "int",
        "groupID": 1,
        "groupOperator": "AND",
        "inlineOperator": "AND"
    }]

    const criterionTemplate = (attr, operator, value) => {
        const attrtibute = attributeData?.[attr];
    
        return {
            id: `${attr}-${value}`,
            formType: attrtibute?.formType,
            criterion: {
                [operator]: value,
            },
            relativeKey: undefined,
            groupID: 0,
            groupOperator: "AND",
            inlineOperator: "AND",
            attr: attr,
        }
    };

    
    const config = {
        session,
        baseModule,
        existingCriteria,
        setExistingCriteria,
        blankCriteriaConfig,
        criterionTemplate,
    }

    const [categories, setCategories] = useState({
        accountTypes : {
            openStatus : false,
            marketing : {
                openStatus : false,
            },
            origination : {
                openStatus : false,
            },
            funds : {
                openStatus : false,
            },
        },
        policyStatus : {
            openStatus : false,
            documentsAndGathering : {
                openStatus : false,
            },
            valuation : {
                openStatus : false,
            },
            contracts : {
                openStatus : false,
            },
            closing : {
                openStatus : false,
            },
            closedAndInactive : {
                openStatus : false,
            },
            closedAndInactive : {
                openStatus : false,
            },
        },
    });

    const updateCategories = (path, value) => {
        setCategories((prevState) => {
            const keys = path.split("."); // Split the path into keys
            const updatedState = { ...prevState }; // Create a shallow copy of the state
            let current = updatedState; // Reference to traverse and modify the state
    
            keys.forEach((key, index) => {
                if (index === keys.length - 1) {
                    current[key] = value;
                } else {
                    current[key] = { ...current[key] };
                    current = current[key];
                }
            });
    
            return updatedState;
        });
    };

    const [dropdowns, setDropdowns] = useState({
        reportDownload : {
            showMenu : true,
        },
        pagination : {
            showMenu : false,
            rowMax : props?.session?.storage?.reporting?.rowMax ?? 100,
            index : selectedReport?.lastPageIndex ?? 1,
        },
    });

    const updateDropdown = (key, attributes) => {
        setDropdowns((prev) => {
            const updatedDropdowns = {
                ...prev,
                [key]: {
                    ...prev[key],
                    ...attributes, // Spread the incoming attributes to update multiple fields
                },
            };
            return updatedDropdowns;
        });
    };

    const resetDropdowns = () => {
        setDropdowns((prev) => {
            const updated = Object.keys(prev).reduce((acc, key) => {
                acc[key] = { ...prev[key], showMenu: false };
                return acc;
            }, {});
            return updated;
        });
    };

    const filterDataPoint = (list, criterionAttr, criterionOperator) => {
        const categoryLength = Object.values(list).flat()?.length || 0;
        const filteredCount = reporting?.functions?.existingCriterion(config,
            reporting?.functions?.dynamicCriterionArray(config,  Object.values(list).flat(), criterionAttr, criterionOperator)
        );

        if(filteredCount && !reporting?.functions?.blankCriteria(config)){
            return (
                <div className="dataPoint gC2 cC lH bR">
                    {`(${filteredCount} / ${categoryLength}) Filters`}
                </div>
            )
        }
        return null;
    }

    const renderItem = ({key, label, criterionAttr, rowIcon, criterionOperator, criterionValue}) => {
        const activeStatus = reporting?.functions?.existingCriterion(config, criterionTemplate(criterionAttr, criterionOperator, criterionValue));
        const hovering = isHovering === `${key}-${label}-${criterionOperator}-${criterionValue}`;
        const handleMouseOver = () => {
            setIsHovering(`${key}-${label}-${criterionOperator}-${criterionValue}`);
        };
    
        const handleMouseOut = () => {
            setIsHovering(undefined);
        };

        return (
            <div
                key={key}
                className={`item g p s bR dG${!activeStatus ? " on" : hovering ? '' : " off"}`}
                onClick={() => {reporting?.functions?.toggleCriteria(config, {attr : criterionAttr, operator : criterionOperator, value : criterionValue});}}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
            >
                <div className={`activeStatus f cC`}>
                    {typeof rowIcon === "function" ?
                        rowIcon(label)
                    :
                        !activeStatus ? (
                            <CheckBoxTwoToneIcon />
                        ) : (
                            <CheckBoxOutlineBlankTwoToneIcon />
                        )
                    }
                </div>
                <div className="f g cL dP gC2 cC">
                    {`${label}`}
                </div>
            </div>
        )
    };

    const renderGroup = ({
        groupOptions, 
        itemList,
        objectData,
        group, 
        groupOption, 
        label, 
        condition, 
        attrPointer,
        criterionOperator,
        criterionAttr, 
        conditionPointer,
        groupIcon,
        rowIcon,
    }) => {
        // Calculate itemList length
        const itemListLength = itemList?.length || 0;

        // Determine inclusion in existing criteria
        const groupInclusion = reporting?.functions?.existingCriterion(config,
            reporting?.functions?.dynamicCriterionArray(config, itemList, criterionAttr, criterionOperator)
        );
        const activeStatus = groupInclusion === 0 ? "all" : groupInclusion === itemListLength ? "none" : "partial";
        // Generate criteria array for toggle

        return (
            <>
                {groupOptions?.some(condition ?? (() => true)) && (
                    <div className="group g bR">
                        <div className="header f g cC s e nS fR dG">
                            <div
                                className={`activeStatus f cC p ${activeStatus}`}
                                onClick={() => {
                                    reporting?.functions?.toggleCriteria(config, reporting?.functions?.groupCriteria(itemList, criterionAttr, criterionOperator));
                                }}
                            >
                                {{
                                    "all": <CheckCircleTwoToneIcon/>,
                                    "none": <CancelTwoToneIcon/>,
                                    "partial": <CircleTwoToneIcon/>,
                                }[activeStatus]}
                            </div>
                            <span
                                className="f cC bold p gC3"
                                onClick={() => {
                                    updateCategories(
                                        `${group}.${groupOption}.openStatus`,
                                        !categories?.[group]?.[groupOption]?.openStatus
                                    );
                                }}
                            >
                                {label}
                            </span>
                            {groupIcon &&
                                <div className="f cC gC4">
                                    {groupIcon}
                                </div>
                            }
                            <div
                                className="expand f cC p gC5"
                                onClick={() =>
                                    updateCategories(
                                        `${group}.${groupOption}.openStatus`,
                                        !categories?.[group]?.[groupOption]?.openStatus
                                    )
                                }
                            >
                                {!categories?.[group]?.[groupOption]?.openStatus ? (
                                    <ExpandLessTwoToneIcon />
                                ) : (
                                    <ExpandMoreTwoToneIcon />
                                )}
                            </div>
                        </div>
                        {categories?.[group]?.[groupOption]?.openStatus && (
                            <div className="list g s e b">
                                <div className="vDivider" />
                                <div className="items g">
                                    {itemList
                                        .filter((item) => {
                                            // If objectData exists, filter using it; otherwise, use itemList directly
                                            const child = objectData?.[item];
                                            const property = child?.[conditionPointer] ?? item;
                                            return typeof condition === "function" ? condition(property) : true;
                                        })
                                        .map((item) => {
                                            const child = objectData?.[item];
                                            return renderItem({
                                                key: item,
                                                label: child?.[attrPointer] ?? item,
                                                criterionAttr,
                                                criterionOperator,
                                                criterionValue: item,
                                                attrPointer,
                                                opportunityType : groupOption,
                                                rowIcon,
                                            });
                                        })}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </>
        );
    };

    return (
        <div className="f dP oA">
            <div
                className="filtersHub f g bR oA dG dP"
                onClick={() => {
                    resetDropdowns(); // Correctly call the function
                }}
            >
            {(() => {
                if (Object?.keys(session?.user?.data?.accountData)?.length === 1) return;

                const groupOptions = Array.from(
                    new Set(
                        Object.values(session?.user?.data?.accountData || {})
                            .map((item) => item?.type)
                            .filter(Boolean)
                    )
                );

                function group(allowedTypes) {
                    const data = session?.user?.data?.accountData;
                    const excludeKey = "opportunityTypes";
                    // Ensure allowedTypes is always treated as an array
                    const allowedTypesArray = Array.isArray(allowedTypes) ? allowedTypes : [allowedTypes];
                
                    return Object.keys(data || {})
                        .filter(
                            key =>
                                key !== excludeKey &&
                                allowedTypesArray.includes(data?.[key]?.type)
                        )
                        .sort((a, b) =>
                            data[a]?.name.localeCompare(data[b]?.name)
                        );
                }

                return (
                    <div className="block g dG dP bR">
                        <div
                            className="btn header cC s g fR p bR nS"
                            onClick={() =>
                                updateCategories(
                                    "accountTypes.openStatus",
                                    !categories?.accountTypes?.openStatus
                                )}
                        >
                            <span className="cL f lH bold g dG">
                                Accounts
                                {filterDataPoint(group(["Marketing", "Agent", "Broker", "Fund"]), "relatedAccountID", "not_match")}
                            </span>
                            <div
                                className="expand f cC"

                            >
                                {!categories?.accountTypes?.openStatus ? (
                                    <ExpandLessTwoToneIcon />
                                ) : (
                                    <ExpandMoreTwoToneIcon />
                                )}
                            </div>
                        </div>
                        {categories?.accountTypes?.openStatus && (
                            <div className="category g dG">
                                <div className="f cC">
                                    <div className="vDivider" />
                                </div>
                                <div className="container f g dG">
                                    <>
                                        {renderGroup({
                                            groupOptions,
                                            itemList : group("Marketing"),
                                            objectData : session?.user?.data?.accountData,
                                            group : "accountTypes",
                                            groupOption : "marketing",
                                            label : "Marketing",
                                            condition : (type) => type === "Marketing",
                                            attrPointer : "name",
                                            criterionOperator : "not_match",
                                            criterionAttr : "relatedAccountID",
                                            conditionPointer : "type",
                                            groupIcon : <BusinessCenterTwoToneIcon  style={{ color: "#795548" }}/>
                                        })}
                                        {renderGroup({
                                            groupOptions,
                                            itemList : group(["Agent", "Broker"]),
                                            objectData : session?.user?.data?.accountData,
                                            group : "accountTypes",
                                            groupOption : "origination",
                                            label : "Origination",
                                            condition : (type) => ["Agent", "Broker"].includes(type),
                                            attrPointer : "name",
                                            criterionOperator : "not_match",
                                            criterionAttr : "relatedAccountID",
                                            conditionPointer: "type",
                                            groupIcon : <ShieldTwoToneIcon style={{ color: "rgb(0, 191, 255)" }}/>
                                        })}
                                        {renderGroup({
                                            groupOptions,
                                            itemList : group("Fund"),
                                            objectData : session?.user?.data?.accountData,
                                            group : "accountTypes",
                                            groupOption : "funds",
                                            label : "Funds",
                                            condition : (type) => type === "Fund",
                                            attrPointer : "name",
                                            criterionOperator : "not_match",
                                            criterionAttr : "relatedAccountID",
                                            conditionPointer: "type",
                                            groupIcon : <SecurityTwoToneIcon style={{ color: "rgb(255, 152, 0)" }}/>
                                        })}
                                    </>
                                </div>
                            </div>
                        )}
                    </div>
                );
            })()}
            {(() => {
                const stems = Array.isArray(selectedReport?.stem)
                    ? selectedReport.stem
                    : [selectedReport?.stem];

                const combinedList = stems.reduce((acc, stem) => {
                    const listForStem = attributeData?.policyStatus?.list?.[stem];
                    if (listForStem) {
                        acc = { ...acc, ...listForStem };
                    }
                    return acc;
                }, {});

                const sortedStatuses = Object.keys(combinedList).sort();
                const groupOptions = ["documentsAndGathering", "valuation", "contracts", "closing", "closedAndInactive"];
                const groupedItems = {
                    "documentsAndGathering" : [
                        // "Lead",
                        "Application Sent",
                        "Meds/Illustration Ordered",
                        "Underwriting (LE's)"
                    ],
                    "valuation" : [
                        "Pre-Priced",
                        "Pricing / Valuation",
                        "Shipped",
                        "Bids"
                    ],
                    "contracts" : [
                        "Offer Letter Out / CRF",
                        "Offer Letter In",
                        "Contracts Out",
                        "Contracts Back",
                        "Contracts Back (Digital)"
                    ],
                    "closing" : [
                        "Escrow / At Carrier",
                        "Rescission",
                    ],
                    "closedAndInactive" : [
                        "Closed Won",
                        "Closed Lost",
                        "On Hold",
                        "Client Not Interested",
                        "Closed Lost no bid",
                        "Closed Lost with Bid",
                        "Unqualified Closed",
                        "Invoicing",
                        "Client Deceased", //NEW
                        "Closed Lost to Competitor", //NEW
                    ],
                    "other" : [
                        "Failed to Contact",
                        "Sent to 3rd Party",
                        "Contact Attempted",
                        "Matured During Rescission",
                        "On Hold/Pre-Priced",
                        "Lead",
                    ]
                };

                function group(list) {
                    if (!Array.isArray(list)) {
                        throw new Error("Input must be an array.");
                    }
                    return [...list].sort((a, b) => a.localeCompare(b));
                }

                return (
                    <div className="block g dG dP bR">
                        <div
                            className="btn header cC s g fR p bR nS"
                            onClick={() =>
                                updateCategories(
                                    "policyStatus.openStatus",
                                    !categories?.policyStatus?.openStatus
                                )
                            }
                        >
                            <span className="cL f lH bold g dG">
                                Statuses
                                {filterDataPoint(groupedItems, "policyStatus", "not_match")}
                            </span>
                            <div className="expand f cC">
                                {!categories?.policyStatus?.openStatus ? (
                                    <ExpandLessTwoToneIcon />
                                ) : (
                                    <ExpandMoreTwoToneIcon />
                                )}
                            </div>
                        </div>
                        {categories?.policyStatus?.openStatus && (
                            <div className="category g dG">
                                <div className="f cC">
                                    <div className="vDivider" />
                                </div>
                                <div className="container f g dG">
                                    <>
                                        {renderGroup({
                                            groupOptions,
                                            itemList : group(groupedItems?.["documentsAndGathering"]),
                                            group : "policyStatus",
                                            groupOption : "documentsAndGathering",
                                            label : "Documents / Gathering",
                                            criterionOperator : "not_match",
                                            criterionAttr : "policyStatus",
                                            rowIcon: (status) => <StatusIcon status={status} type={"Status"} />,
                                            groupIcon : <StartIcon  style={{ color: "#f57f17" }}/>
                                        })}
                                        {renderGroup({
                                            groupOptions,
                                            itemList : group(groupedItems?.["valuation"]),
                                            group : "policyStatus",
                                            groupOption : "valuation",
                                            label : "Valuation",
                                            criterionOperator : "not_match",
                                            criterionAttr : "policyStatus",
                                            rowIcon: (status) => <StatusIcon status={status} type={"Status"} />,
                                            groupIcon : <DiamondTwoToneIcon  style={{ color: "#33691e" }}/>
                                        })}
                                        {renderGroup({
                                            groupOptions,
                                            itemList : group(groupedItems?.["contracts"]),
                                            group : "policyStatus",
                                            groupOption : "contracts",
                                            label : "Contracts",
                                            criterionOperator : "not_match",
                                            criterionAttr : "policyStatus",
                                            rowIcon: (status) => <StatusIcon status={status} type={"Status"} />,
                                            groupIcon : <GavelIcon  style={{ color: "#1b5e20" }}/>
                                        })}
                                        {renderGroup({
                                            groupOptions,
                                            itemList : group(groupedItems?.["closing"]),
                                            group : "policyStatus",
                                            groupOption : "closing",
                                            label : "Closing",
                                            criterionOperator : "not_match",
                                            criterionAttr : "policyStatus",
                                            rowIcon: (status) => <StatusIcon status={status} type={"Status"} />,
                                            groupIcon : <TaskTwoToneIcon  style={{ color: "#1565c0" }}/>
                                        })}
                                        {renderGroup({
                                            groupOptions,
                                            itemList : group(groupedItems?.["closedAndInactive"]),
                                            group : "policyStatus",
                                            groupOption : "closedAndInactive",
                                            label : "Closed / Inactive",
                                            criterionOperator : "not_match",
                                            criterionAttr : "policyStatus",
                                            rowIcon: (status) => <StatusIcon status={status} type={"Status"} />,
                                            groupIcon : <FlakyIcon  style={{ color: "#ff9800" }}/>
                                        })}
                                        {renderGroup({
                                            groupOptions,
                                            itemList : group(groupedItems?.["other"]),
                                            group : "policyStatus",
                                            groupOption : "other",
                                            label : "Other",
                                            criterionOperator : "not_match",
                                            criterionAttr : "policyStatus",
                                            rowIcon: (status) => <StatusIcon status={status} type={"Status"} />,
                                            groupIcon : <AutoAwesomeTwoToneIcon  style={{ color: "#673ab7" }}/>
                                        })}
                                    </>
                                </div>
                            </div>
                        )}
                    </div>
                );
            })()}
        </div>
    </div>)
}

export default Filters;